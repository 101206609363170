

import Dashboard from "views/Client/Dashboard/Dashboard.js"
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import ClientLogin from "views/Pages/ClientLogin";
import ClientRegister from "views/Pages/ClientRegister";
import SignUp from "views/Pages/SignUp.js";

// add managemenet/ingame adds module

   
import CharactersModule from "views/adsManagement/inGame/Characters";
import Assets from "views/Client/Assets/Assets";
import Analyse from "views/Client/Analyse/Analyse";
import Payments from "views/Client/Payments/Payments";
import Help from "views/Client/Help/Help";
import ClientAds from "views/Client/ClientAds/ClientAds"

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  CharactersIcon,
} from "components/Icons/Icons";

import ClientCharacterList from "views/Client/ClientCharacterList";
import ClientCharacterDetails from "views/Client/ClientCharacterDetails/ClientCharacterDetails";

var clientRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/client",
  },

  {
    path: "/assets",
    name: "Assets",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Assets,
    layout: "/client",
  },

  
  {
    path: "/analytics",
    name: "Analytics",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Analyse,
    layout: "/client",
  },

  {
    path: "/payments",
    name: "Payments",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Payments,
    layout: "/client",
  },

  {
    path: "/help",
    name: "Help",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Help,
    layout: "/client",
  },
 
  {
    path: "/character/list",
    name: "Characters",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: ClientCharacterList,
    layout: "/client",
  },

  {
    path: "/ads/list",
    name: "Ads",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: ClientAds,
    layout: "/client",
  },

  {
    path: "/character-details/:id",
    name: "CharacterDetails",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: ClientCharacterDetails,
    layout: "/client",
  },

  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: ClientLogin,
    layout: "/client-auth",
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: ClientRegister,
    layout: "/client-auth",
  },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       rtlName: "لوحة القيادة",
  //       icon: <DocumentIcon color='inherit' />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default clientRoutes;
