import React, { useState, useEffect } from "react";
import { Flex, Table, Tbody, Td, Th, Thead, Tr, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

function ProjectList() {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingProject, setEditingProject] = useState(null);
  const [formData, setFormData] = useState({
    project_name: "",
    gameGenre: "",
    studioName: "",
    gameEngine: "",
  });
  const [errors, setErrors] = useState({});

  // Fetch projects from the backend
  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("developerToken");
      const response = await fetch(`https://www.portal.seiky.in/seiky-backend/api/developer/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/developer-auth/login");
          return;
        }
        throw new Error("Failed to fetch projects");
      }

      const data = await response.json();
      setProjects(data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load projects on component mount
  useEffect(() => {
    fetchProjects();
  }, []);

  // Handle editing a project
  const handleEdit = (project) => {
    setEditingProject(project);
    setFormData({
      project_name: project.project_name || "",
      gameGenre: project.gameGenre || "",
      studioName: project.studioName || "",
      gameEngine: project.gameEngine || "",
    });
    onOpen();
  };

  // Handle adding a new project
  const handleAddProject = () => {
    setEditingProject(null);
    setFormData({ project_name: "", gameGenre: "", studioName: "", gameEngine: "" });
    onOpen();
  };

  // Handle deleting a project
  const handleDelete = async (project) => {
    try {
      const token = localStorage.getItem("developerToken");
      const response = await fetch(`https://www.portal.seiky.in/seiky-backend/api/developer/projects/${project._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/auth/signin");
          return;
        }
        throw new Error("Failed to delete project");
      }
      fetchProjects();
      alert("Project deleted successfully");
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  // Handle input changes in the form
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form input
  const validateForm = () => {
    const newErrors = {};
    if (!formData.project_name) newErrors.project_name = "Project Name is required";
    if (!formData.gameGenre) newErrors.gameGenre = "Game Genre is required";
    if (!formData.studioName) newErrors.studioName = "Studio Name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange = (e, fieldType) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldType]: file, // Save the file to the formData state
      }));
    }
  };

  // Handle form submission for adding/editing a project
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const method = editingProject ? "PUT" : "POST";
  const url = editingProject
    ? `https://www.portal.seiky.in/seiky-backend/api/developer/projects/${editingProject._id}`
    : "https://www.portal.seiky.in/seiky-backend/api/developer/projects";

  const token = localStorage.getItem("developerToken");

  // Check if we need to use FormData (for file uploads)
  let isFileUpload = formData.gameplayVideo || formData.gameBrief;

  let payload;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (isFileUpload) {
    // Use FormData if there are files to be uploaded
    payload = new FormData();
    payload.append("project_name", formData.project_name);
    payload.append("game_genre", formData.gameGenre);
    payload.append("studio_name", formData.studioName);
    payload.append("game_engine", formData.gameEngine);

    if (formData.gameplayVideo) {
      payload.append("gameplayVideo", formData.gameplayVideo);
    }
    if (formData.gameBrief) {
      payload.append("gameBrief", formData.gameBrief);
    }
  } else {
    // Use JSON for payload if no files are involved
    payload = JSON.stringify({
      project_name: formData.project_name,
      game_genre: formData.gameGenre,
      studio_name: formData.studioName,
      game_engine: formData.gameEngine,
    });
    headers["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(url, {
      headers,
      method,
      body: payload,
    });

    if (!response.ok) {
      if (response.status === 401) {
        history.push("/developer/login");
        return;
      }
      throw new Error("Failed to save project");
    }

    onClose();
    fetchProjects();
  } catch (error) {
    console.error("Error saving project:", error);
  }
};


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddProject} alignSelf="flex-end" mb="4" colorScheme="blue">
        Add Project
      </Button>
      <Table variant="simple" color="white">
        <Thead>
          <Tr>
            <Th>Project Name</Th>
            <Th>Game Genre</Th>
            <Th>Studio Name</Th>
            <Th>Game Engine</Th>
            <Th>Last Update Time </Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project, key) => (
            <Tr key={key}>
              <Td>{project.project_name}</Td>
              <Td>{project.game_genre}</Td>
              <Td>{project.studio_name}</Td>
              <Td>{project.game_engine}</Td>
              <Td>{new Date(project.updated_at).toLocaleDateString()}</Td>
              <Td>
                <Button colorScheme="blue" onClick={() => handleEdit(project)}>
                  Edit
                </Button>
                <Button colorScheme="red" ml={2} onClick={() => handleDelete(project)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for adding/editing projects */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>{editingProject ? "Edit Project" : "Add a New Project"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {/* Project Name Field */}
              <FormControl isInvalid={!!errors.project_name}>
                <FormLabel>Project Name</FormLabel>
                <Input
                  name="project_name"
                  value={formData.project_name || ""}
                  onChange={handleChange}
                  placeholder="Project Name"
                />
                {errors.project_name && <FormErrorMessage>{errors.project_name}</FormErrorMessage>}
              </FormControl>

              {/* Game Genre Field */}
              <FormControl mt={4} isInvalid={!!errors.gameGenre}>
                <FormLabel>Game Genre</FormLabel>
                <Input
                  name="gameGenre"
                  value={formData.gameGenre || ""}
                  onChange={handleChange}
                  placeholder="Game Genre"
                />
                {errors.gameGenre && <FormErrorMessage>{errors.gameGenre}</FormErrorMessage>}
              </FormControl>

              {/* Studio Name Field */}
              <FormControl mt={4} isInvalid={!!errors.studioName}>
                <FormLabel>Studio Name</FormLabel>
                <Input
                  name="studioName"
                  value={formData.studioName || ""}
                  onChange={handleChange}
                  placeholder="Studio Name"
                />
                {errors.studioName && <FormErrorMessage>{errors.studioName}</FormErrorMessage>}
              </FormControl>

              {/* Game Engine Field */}
              <FormControl mt={4}>
                <FormLabel>Game Engine</FormLabel>
                <Input
                  name="gameEngine"
                  value={formData.gameEngine || ""}
                  onChange={handleChange}
                  placeholder="Game Engine"
                />
              </FormControl>

              {/* Gameplay Video Upload Field */}
              <FormControl mt={4}>
                <FormLabel>Upload Gameplay Video</FormLabel>
                <Input
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleFileChange(e, "gameplayVideo")}
                />
              </FormControl>

              {/* Game Brief Upload Field */}
              <FormControl mt={4}>
                <FormLabel>Upload Game Brief (Doc)</FormLabel>
                <Input
                  type="file"
                  accept=".doc,.docx,.pdf"
                  onChange={(e) => handleFileChange(e, "gameBrief")}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingProject ? "Update" : "Submit"}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default ProjectList;
