import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function TablesTableRow(props) {

  const { ad, onEdit,onDelete} = props;

  const handleEditClick = () => {
    onEdit(ad); // Call the edit function passed from the parent
  };

  const handleDeleteClick = () => {
    onDelete(ad);
  }

  const {
    name,
    description,
    image,
    video,
    shirt_image,
    floor_image,
    lastItem
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps='0px'
        border={lastItem ? "none" : null}
        borderBottomColor='#56577A'>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Flex direction='column'>
            <Text
              fontSize='sm'
              color='#fff'
              fontWeight='normal'
              minWidth='100%'>
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td
        border={lastItem ? "none" : null}
        borderBottomColor='#56577A'
        minW='150px'>
        <Flex direction='column'>
          <Text fontSize='sm' color='#fff' fontWeight='normal'>
            {description}
          </Text>
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' minW='150px'>
        <Flex direction='column'>
          <Image src={image} width={100} height={100} alt="Image" />
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' minW='150px'>
        <Flex direction='column'>
          <video width="100" height="100" controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' minW='150px'>
        <Flex direction='column'>
          <Image src={shirt_image} width={100} height={100} alt="Image" />
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A' minW='150px'>
        <Flex direction='column'>
          <Image src={floor_image} width={100} height={100} alt="Image" />
        </Flex>
      </Td>
     
      <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
        <Button p='0px' bg='transparent' onClick={handleEditClick} variant='no-hover'>
          <Text
            fontSize='sm'
            color='gray.400'
            fontWeight='bold'
            cursor='pointer'>
            <i className="fa fa-edit"></i>
          </Text>
        </Button>

        <Button p='0px' bg='transparent' onClick={handleDeleteClick} variant='no-hover'>
          <Text
            fontSize='sm'
            color='red'
            fontWeight='bold'
            cursor='pointer'>
            <i className="fa fa-trash"></i>
          </Text>
        </Button>

      </Td>
    </Tr>
  );
}

export default TablesTableRow;
