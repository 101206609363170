// import React from 'react';
// import {
//   Flex,
//   Box,
//   Heading,
//   Text,
//   Accordion,
//   AccordionItem,
//   AccordionButton,
//   AccordionPanel,
//   AccordionIcon,
//   List,
//   ListItem,
//   ListIcon,
//   Divider
// } from '@chakra-ui/react';
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
// import { InfoOutlineIcon, CheckCircleIcon } from '@chakra-ui/icons';

// export default function Analyse() {
//   return (
//     <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
//       <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
//         <CardHeader p='6px 0px 22px 0px'>
//           <Text fontSize='lg' fontWeight='bold' color={`white`}>
//             Analysis
//           </Text>
//         </CardHeader>
//         <CardBody>
//           <Box p={6} maxW="800px" mx="auto" color="white">
//             <Heading as="h1" size="xl" mb={4} textAlign="center">
//               Analysis Overview
//             </Heading>
//             <Text fontSize="lg" mb={6}>
//               This section provides an overview of the analysis process, including insights into data collection, processing, and visualization, helping users make informed decisions.
//             </Text>
//             <Divider mb={6} />
//             <Accordion allowToggle>
//               <AccordionItem>
//                 <AccordionButton>
//                   <Box flex="1" textAlign="left">
//                     Step 1: Data Collection
//                   </Box>
//                   <AccordionIcon />
//                 </AccordionButton>
//                 <AccordionPanel pb={4}>
//                   <Text mb={2}>
//                     The analysis process starts with data collection. Gathering accurate data from various sources is crucial to ensure that the analysis is reliable and meaningful.
//                   </Text>
//                   <List spacing={3}>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Data Sources: Sensors, User Inputs, External APIs.
//                     </ListItem>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Data Accuracy: Make sure data is complete and validated before proceeding.
//                     </ListItem>
//                   </List>
//                 </AccordionPanel>
//               </AccordionItem>

//               <AccordionItem>
//                 <AccordionButton>
//                   <Box flex="1" textAlign="left">
//                     Step 2: Data Processing
//                   </Box>
//                   <AccordionIcon />
//                 </AccordionButton>
//                 <AccordionPanel pb={4}>
//                   <Text mb={2}>
//                     After collecting data, it is processed to remove inconsistencies and prepare it for analysis. This includes data cleaning, transformation, and integration.
//                   </Text>
//                   <Text>
//                     <InfoOutlineIcon mr={2} />Data processing ensures consistency and makes the data suitable for analysis.
//                   </Text>
//                 </AccordionPanel>
//               </AccordionItem>

//               <AccordionItem>
//                 <AccordionButton>
//                   <Box flex="1" textAlign="left">
//                     Step 3: Data Analysis
//                   </Box>
//                   <AccordionIcon />
//                 </AccordionButton>
//                 <AccordionPanel pb={4}>
//                   <Text mb={2}>
//                     In this step, data is analyzed using various tools and techniques. This includes statistical analysis, pattern recognition, and trend identification to derive meaningful insights.
//                   </Text>
//                   <List spacing={3}>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Tools: Machine Learning Models, Statistical Analysis.
//                     </ListItem>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Ensure the analysis aligns with the objectives of the project.
//                     </ListItem>
//                   </List>
//                 </AccordionPanel>
//               </AccordionItem>

//               <AccordionItem>
//                 <AccordionButton>
//                   <Box flex="1" textAlign="left">
//                     Step 4: Visualization
//                   </Box>
//                   <AccordionIcon />
//                 </AccordionButton>
//                 <AccordionPanel pb={4}>
//                   <Text mb={2}>
//                     Visualization is key to understanding the results of the analysis. Data is represented in charts, graphs, and interactive dashboards to help make informed decisions.
//                   </Text>
//                   <Text>
//                     <InfoOutlineIcon mr={2} />Effective visualization helps in communicating insights clearly and efficiently.
//                   </Text>
//                 </AccordionPanel>
//               </AccordionItem>

//               <AccordionItem>
//                 <AccordionButton>
//                   <Box flex="1" textAlign="left">
//                     Step 5: Decision Making
//                   </Box>
//                   <AccordionIcon />
//                 </AccordionButton>
//                 <AccordionPanel pb={4}>
//                   <Text mb={2}>
//                     The final step is to use the insights derived from the analysis to make informed decisions. This could involve adjusting processes, setting new goals, or identifying areas of improvement.
//                   </Text>
//                   <List spacing={3}>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Review the findings with stakeholders.
//                     </ListItem>
//                     <ListItem>
//                       <ListIcon as={CheckCircleIcon} color="green.500" />
//                       Develop an action plan based on the results.
//                     </ListItem>
//                   </List>
//                 </AccordionPanel>
//               </AccordionItem>
//             </Accordion>
//           </Box>
//         </CardBody>
//       </Card>
//     </Flex>
//   );
// }

import {
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Grid,
	Icon,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr
} from '@chakra-ui/react';
// Styles for the circular progressbar
import medusa from 'assets/img/cardimgfree.png';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import IconBox from 'components/Icons/IconBox';
// Icons
import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from 'components/Icons/Icons.js';
import DashboardTableRow from 'components/Tables/DashboardTableRow';
import TimelineRow from 'components/Tables/TimelineRow';
import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiHappy } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { IoCheckmarkDoneCircleSharp, IoEllipsisHorizontal } from 'react-icons/io5';
// Data
import {
	barChartDataDashboard,
	barChartOptionsDashboard,
	lineChartDataDashboard,
	lineChartOptionsDashboard
} from 'variables/charts';
import { dashboardTableData, timelineData } from 'variables/general';

export default function Analyse() {
	return (
		<Flex flexDirection='column' pt={{ base: '120px', md: '75px' }}>
			
			<Grid templateColumns={{ sm: '1fr', lg: '1.7fr 1.3fr' }}
				maxW={{ sm: '100%', md: '100%' }}
				gap='24px'
				mb='24px'>
				
				{/* Satisfaction Rate */}
				<Card gridArea={{ md: '2 / 1 / 3 / 2', '2xl': 'auto' }}>
					<CardHeader mb='24px'>
						<Flex direction='column'>
							<Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
								Satisfaction Rate
							</Text>
							<Text color='gray.400' fontSize='sm'>
								From all projects
							</Text>
						</Flex>
					</CardHeader>
					<Flex direction='column' justify='center' align='center'>
						<Box zIndex='-1'>
							<CircularProgress
								size={200}
								value={80}
								thickness={6}
								color='#582CFF'
								variant='vision'
								rounded>
								<CircularProgressLabel>
									<IconBox mb='20px' mx='auto' bg='brand.200' borderRadius='50%' w='48px' h='48px'>
										<Icon as={BiHappy} color='#fff' w='30px' h='30px' />
									</IconBox>
								</CircularProgressLabel>
							</CircularProgress>
						</Box>
						<Stack
							direction='row'
							spacing={{ sm: '42px', md: '68px' }}
							justify='center'
							maxW={{ sm: '270px', md: '300px', lg: '100%' }}
							mx={{ sm: 'auto', md: '0px' }}
							p='18px 22px'
							bg='linear-gradient(126.97deg, rgb(6, 11, 40) 28.26%, rgba(10, 14, 35) 91.2%)'
							borderRadius='20px'
							position='absolute'
							bottom='5%'>
							<Text fontSize='xs' color='gray.400'>
								0%
							</Text>
							<Flex direction='column' align='center' minW='80px'>
								<Text color='#fff' fontSize='28px' fontWeight='bold'>
									95%
								</Text>
								<Text fontSize='xs' color='gray.400'>
									Based on likes
								</Text>
							</Flex>
							<Text fontSize='xs' color='gray.400'>
								100%
							</Text>
						</Stack>
					</Flex>
				</Card>
				{/* Referral Tracking */}
				<Card gridArea={{ md: '2 / 2 / 3 / 3', '2xl': 'auto' }}>
					<Flex direction='column'>
						<Flex justify='space-between' align='center' mb='40px'>
							<Text color='#fff' fontSize='lg' fontWeight='bold'>
								Referral Tracking
							</Text>
							<Button borderRadius='12px' w='38px' h='38px' bg='#22234B' _hover='none' _active='none'>
								<Icon as={IoEllipsisHorizontal} color='#7551FF' />
							</Button>
						</Flex>
						<Flex direction={{ sm: 'column', md: 'row' }}>
							<Flex direction='column' me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }}>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22e', md: '8px', lg: '22px' }}
									minW={{ sm: '220px', md: '140px', lg: '220px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'
									mb='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										Invited
									</Text>
									<Text color='#fff' fontSize='lg' fontWeight='bold'>
										145 people
									</Text>
								</Flex>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22px', md: '8px', lg: '22px' }}
									minW={{ sm: '170px', md: '140px', lg: '170px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										Bonus
									</Text>
									<Text color='#fff' fontSize='lg' fontWeight='bold'>
										1,465
									</Text>
								</Flex>
							</Flex>
							<Box mx={{ sm: 'auto', md: '0px' }}>
								<CircularProgress
									size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
									value={70}
									thickness={6}
									color='#05CD99'
									variant='vision'>
									<CircularProgressLabel>
										<Flex direction='column' justify='center' align='center'>
											<Text color='gray.400' fontSize='sm'>
												Safety
											</Text>
											<Text
												color='#fff'
												fontSize={{ md: '36px', lg: '50px' }}
												fontWeight='bold'
												mb='4px'>
												9.3
											</Text>
											<Text color='gray.400' fontSize='sm'>
												Total Score
											</Text>
										</Flex>
									</CircularProgressLabel>
								</CircularProgress>
							</Box>
						</Flex>
					</Flex>
				</Card>
			</Grid>
			<Grid
				templateColumns={{ sm: '1fr', lg: '1.7fr 1.3fr' }}
				maxW={{ sm: '100%', md: '100%' }}
				gap='24px'
				mb='24px'>
				{/* Sales Overview */}
				<Card p='28px 0px 0px 0px'>
					<CardHeader mb='20px' ps='22px'>
						<Flex direction='column' alignSelf='flex-start'>
							<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
								Sales Overview
							</Text>
							<Text fontSize='md' fontWeight='medium' color='gray.400'>
								<Text as='span' color='green.400' fontWeight='bold'>
									(+5%) more
								</Text>{' '}
								in 2021
							</Text>
						</Flex>
					</CardHeader>
					<Box w='100%' minH={{ sm: '300px' }}>
						<LineChart
							lineChartData={lineChartDataDashboard}
							lineChartOptions={lineChartOptionsDashboard}
						/>
					</Box>
				</Card>
				{/* Active Users */}
				<Card p='16px'>
					<CardBody>
						<Flex direction='column' w='100%'>
							<Box
								bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
								borderRadius='20px'
								display={{ sm: 'flex', md: 'block' }}
								justify={{ sm: 'center', md: 'flex-start' }}
								align={{ sm: 'center', md: 'flex-start' }}
								minH={{ sm: '180px', md: '220px' }}
								p={{ sm: '0px', md: '22px' }}>
								<BarChart
									barChartOptions={barChartOptionsDashboard}
									barChartData={barChartDataDashboard}
								/>
							</Box>
							<Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
								<Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
									Active Users
								</Text>
								<Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										(+23%)
									</Text>{' '}
									than last week
								</Text>
							</Flex>
							<SimpleGrid gap={{ sm: '12px' }} columns={4}>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<WalletIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Users
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										32,984
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={20} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<RocketIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Clicks
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										2.42m
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={90} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<CartIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Sales
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										2,400$
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={30} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<StatsIcon h={'15px'} w={'15px'} color='#fff' />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Items
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}
										color='#fff'
										fontWeight='bold'
										mb='6px'
										my='6px'>
										320
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={50} />
								</Flex>
							</SimpleGrid>
						</Flex>
					</CardBody>
				</Card>
			</Grid>
			
		</Flex>
	);
}
