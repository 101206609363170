// components/Model3D.js
import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const Model3D = ({ modelUrl }) => {
  const extension = modelUrl.split(".").pop().toLowerCase();

  // Unconditionally declare the loader type
  const loader = extension === 'fbx' ? FBXLoader : GLTFLoader;

  // Always call the same hook regardless of the extension
  const model = useLoader(loader, modelUrl, (loaderInstance) => {
    if (loader === GLTFLoader) {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/'); // Set the path to your Draco decoder files
      loaderInstance.setDRACOLoader(dracoLoader);
    }
  });

  const modelRef = useRef();

  return <primitive ref={modelRef} object={model.scene || model} scale={10.5} />;
};

export default Model3D;
