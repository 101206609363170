/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import AuthLayout from "layouts/Auth.js";
import ClientAuthLayout from "layouts/ClientAuth"
import DeveloperAuthLayout from "layouts/DeveloperAuth";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import ClientLayout from "layouts/Client";
import DeveloperLayout from "layouts/Developer";
import Home from "views/frontend/Home";


ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/client-auth`} component={ClientAuthLayout} />
      <Route path={`/developer-auth`} component={DeveloperAuthLayout} />
      <Route path={`/admin`} component={AdminLayout} />
      <Route path={`/rtl`} component={RTLLayout} />
      <Route path={`/client`} component={ClientLayout} />
      <Route path={`/developer`} component={DeveloperLayout} />
      <Route path={`/home`} component={Home} />
      <Redirect from={`/`} to="/home" />
      {/* <Redirect from={`/`} to='/auth/signin' /> */}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
