import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "config";

export default function Assets() {

  const history = useHistory(); // For navigation after successful login

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingProject, setEditingProject] = useState(null);
  const [formData, setFormData] = useState({
    project_name: "",
    project_type: "",
    add_type: "",
  });
  const [errors, setErrors] = useState({});

  // Fetch client projects from the backend
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/client/projects`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("clientToken")}`,
        },
      });
      setProjects(response.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load projects on component mount
  useEffect(() => {
    fetchProjects();
  }, []);

  // Handle editing a project
  const handleEdit = (project) => {
    setEditingProject(project);
    setFormData({
      project_name: project.project_name || "",
      project_type: project.project_type || "",
      add_type: project.add_type || "",
    });
    onOpen();
  };

  // Handle adding a new project
  const handleAddProject = () => {
    setEditingProject(null);
    setFormData({ project_name: "", project_type: "", add_type: "" });
    onOpen();
  };

  // Handle deleting a project
  const handleDelete = async (project) => {
    try {
      await axios.delete(`${API_BASE_URL}/client/project/${project._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("clientToken")}`,
        },
      });
      setProjects((prevProjects) => prevProjects.filter((p) => p._id !== project._id));
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  // Handle input changes in the form
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form input
  const validateForm = () => {
    const newErrors = {};
    if (!formData.project_name) newErrors.project_name = "Project Name is required";
    if (!formData.project_type) newErrors.project_type = "Project Type is required";
    if (!formData.add_type) newErrors.add_type = "Add Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission for adding/editing a project
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const method = editingProject ? "PUT" : "POST";
    const url = editingProject
      ? `${API_BASE_URL}/client/project/${editingProject._id}`
      : `${API_BASE_URL}/client/project`;

    const payload = {
      project_name: formData.project_name,
      project_type: formData.project_type,
      add_type: formData.add_type,
    };

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("clientToken")}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios({ method, url, headers, data: payload });
      const data = response.data; // Access API response data
      onClose();

      if (data.project_type === 'Ads Module') {
        history.push('/client/ads/list'); // Redirect to ads
      } else if (data.project_type === 'Avatar Module') {
        history.push('/client/character/list'); // Redirect to characters
      }

      // fetchProjects();
    } catch (error) {
      console.error("Error saving project:", error);
    }
  };


  if (isLoading) {
    return (
      <Box textAlign="center" p={6}>
        <Spinner size="xl" />
        <Text mt={4}>Loading projects...</Text>
      </Box>
    );
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddProject} alignSelf="flex-end" mb="4" colorScheme="blue">
        New Project
      </Button>
      <Table variant="simple" color="white">
        <Thead>
          <Tr>
            <Th color="white">Project Name</Th>
            <Th color="white">Project Type</Th>
            <Th color="white">Add Type</Th>
            <Th color="white">Created At</Th>
            <Th color="white">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project, key) => (
            <Tr key={key}>
              <Td>{project.project_name}</Td>
              <Td>{project.project_type}</Td>
              <Td>{project.add_type}</Td>
              <Td>{new Date(project.created_at).toLocaleDateString()}</Td>
              <Td>
                <Button colorScheme="blue" onClick={() => handleEdit(project)}>
                  Edit
                </Button>
                <Button colorScheme="red" ml={2} onClick={() => handleDelete(project)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for adding/editing projects */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>{editingProject ? "Edit Project" : "Add a New Project"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={!!errors.project_name}>
                <FormLabel>Project Name</FormLabel>
                <Input
                  name="project_name"
                  value={formData.project_name || ""}
                  onChange={handleChange}
                  placeholder="Project Name"
                />
                {errors.project_name && <FormErrorMessage>{errors.project_name}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.project_type}>
                <FormLabel>Project Type</FormLabel>
                <Select
                  name="project_type"
                  value={formData.project_type || ""}
                  onChange={handleChange}
                  placeholder="Select Project Type"
                >
                  <option value="Avatar Module">Avatar Module</option>
                  <option value="Ads Module">Ads Module</option>
                  {/* <option value="Other">Other</option> */}
                </Select>
                {errors.project_type && <FormErrorMessage>{errors.project_type}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.add_type}>
                <FormLabel>Add Type</FormLabel>
                <Input
                  name="add_type"
                  value={formData.add_type || ""}
                  onChange={handleChange}
                  placeholder="Add Type"
                />
                {errors.add_type && <FormErrorMessage>{errors.add_type}</FormErrorMessage>}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingProject ? "Update" : "Submit"}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
