
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import ClientModelView from '../ClientModelView';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import './ClientCharacterDetails.css';
import { fetchClientCharacterDetails } from 'services/clientCharacterServices';


const ClientCharacterDetails = () => {
  const { id } = useParams();
  const [characterData, setCharacterData] = useState(null);
  const fileInputRef = useRef(null);
  const modelRef = useRef(null);

  const defaultOutfit = {
    torso: {
      modelUrl: 'https://www.portal.seiky.in/seiky-backend/uploads/top_characters/3dmodels/threeDModel-1729755952501-45026691.glb',
    },
    pants: {
      modelUrl: 'https://www.portal.seiky.in/seiky-backend/uploads/bottom_characters/3dmodels/threeDModel-1729756187779-711942152.glb',
    },
    shoes: {
      modelUrl: 'https://www.portal.seiky.in/seiky-backend/uploads/shoes_characters/3dmodels/threeDModel-1729756232743-42521679.glb',
    },
  };
  
  const [currentModels, setCurrentModels] = useState(defaultOutfit);
  const [brandLogo, setBrandLogo] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [logoPosition, setLogoPosition] = useState({ x: 0.2, y: 0.5 });
  const [pantsLogoPosition, setPantsLogoPosition] = useState({ x: 0.3, y: 0.4 });
  const [shoesLogoPosition, setShoesLogoPosition] = useState({ x: 0.1, y: 0.2 });
  const [selectedLogoPart, setSelectedLogoPart] = useState('');

  useEffect(() => {
    const loadCharacterData = async () => {
      const data = await fetchClientCharacterDetails(id);
      setCharacterData(data);
    };
    loadCharacterData();
  }, [id]);

  const applyModel = (part, modelUrl, textureUrl) => {
    setCurrentModels((prev) => ({
      ...prev,
      [part]: { modelUrl, textureUrl },
    }));
    setBrandLogo(null);
    setIsFileSelected(false);
    setSelectedLogoPart('');
    fileInputRef.current.value = null;
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBrandLogo(e.target.result);
        setSelectedLogoPart('');
      };
      reader.readAsDataURL(file);
      setIsFileSelected(true);
    }
  };

  const handleExport = () => {
    if (modelRef.current) {
      let partToExport;

      // Select the part to export
      switch (selectedLogoPart) {
        case 'torso':
          partToExport = modelRef.current.getObjectByName('Wolf3D_Outfit_Top');
          break;
        case 'pants':
          partToExport = modelRef.current.getObjectByName('Wolf3D_Outfit_Bottom');
          break;
        case 'shoes':
          partToExport = modelRef.current.getObjectByName('Wolf3D_Outfit_Footwear');
          break;
        default:
          console.log('No part selected for export');
          return;
      }

      if (!partToExport) {
        console.log('The selected part was not found in the model.');
        return;
      }

      const exporter = new GLTFExporter();
      exporter.parse(partToExport, (result) => {
        const blob = new Blob([result], { type: 'model/gltf-binary' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `customized_${selectedLogoPart}.glb`;
        link.click();
      }, { binary: true });
    }
  };

  if (!characterData) return <div className="loading-screen">Loading...</div>;

  const { character, tops, bottoms, shoes } = characterData;

  return (
    <div className="character-detail-container">
      <div className="character-view">
        <h1 className="character-name">{character.modelName}</h1>
        <div className="logo-controls">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleLogoUpload}
          />

          <h4>Select Part for Logo Application:</h4>
          <select
            className="form-control"
            value={selectedLogoPart}
            onChange={(e) => setSelectedLogoPart(e.target.value)}
            disabled={!isFileSelected}
          >
            <option value="">Select Part</option>
            <option value="torso">Torso</option>
            <option value="pants">Pants</option>
            <option value="shoes">Shoes</option>
          </select>

          <h4>{selectedLogoPart.charAt(0).toUpperCase() + selectedLogoPart.slice(1)} Logo Position:</h4>
          <label>Position X:</label>
          <input
            type="range"
            min="-1"
            max="1"
            step="0.01"
            value={selectedLogoPart === 'torso' ? logoPosition.x : selectedLogoPart === 'pants' ? pantsLogoPosition.x : shoesLogoPosition.x}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (selectedLogoPart === 'torso') {
                setLogoPosition({ ...logoPosition, x: value });
              } else if (selectedLogoPart === 'pants') {
                setPantsLogoPosition({ ...pantsLogoPosition, x: value });
              } else if (selectedLogoPart === 'shoes') {
                setShoesLogoPosition({ ...shoesLogoPosition, x: value });
              }
            }}
          />
          <label>Position Y:</label>
          <input
            type="range"
            min="-1"
            max="1"
            step="0.01"
            value={selectedLogoPart === 'torso' ? logoPosition.y : selectedLogoPart === 'pants' ? pantsLogoPosition.y : shoesLogoPosition.y}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (selectedLogoPart === 'torso') {
                setLogoPosition({ ...logoPosition, y: value });
              } else if (selectedLogoPart === 'pants') {
                setPantsLogoPosition({ ...pantsLogoPosition, y: value });
              } else if (selectedLogoPart === 'shoes') {
                setShoesLogoPosition({ ...shoesLogoPosition, y: value });
              }
            }}
          />
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <Canvas className="character-canvas">
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} intensity={1.5} castShadow />
            <pointLight position={[-10, -10, -10]} intensity={0.7} />
            <PerspectiveCamera makeDefault position={[0, 2, 5]} fov={50} />
            <ClientModelView
              ref={modelRef}
              url={character.threeDModel}
              models={currentModels}
              bodyTextureUrl={character.texture}
              brandLogo={selectedLogoPart === 'torso' ? brandLogo : null}
              logoPosition={selectedLogoPart === 'torso' ? logoPosition : null}
              logoSize={{ width: 0.2, height: 0.2 }}
              pantsLogo={selectedLogoPart === 'pants' ? brandLogo : null}
              pantsLogoPosition={pantsLogoPosition}
              shoesLogo={selectedLogoPart === 'shoes' ? brandLogo : null}
              shoesLogoPosition={shoesLogoPosition}
            />
            <OrbitControls />
          </Canvas>
        </Suspense>
      </div>
      <button onClick={handleExport}>Export 3D Model</button>

      <div className="character-customization">
        <h3 className="section-title">Tops</h3>
        <div className="item-grid">
          {tops.map((top) => (
            <img
              key={top.name}
              src={top.image}
              alt={top.name}
              className="item-icon clickable"
              onClick={() => applyModel('torso', top.threeDModel, top.texture)}
            />
          ))}
        </div>

        <h3 className="section-title">Bottoms</h3>
        <div className="item-grid">
          {bottoms.map((bottom) => (
            <img
              key={bottom.name}
              src={bottom.image}
              alt={bottom.name}
              className="item-icon clickable"
              onClick={() => applyModel('pants', bottom.threeDModel, bottom.texture)}
            />
          ))}
        </div>

        <h3 className="section-title">Shoes</h3>
        <div className="item-grid">
          {shoes.map((shoe) => (
            <img
              key={shoe.name}
              src={shoe.image}
              alt={shoe.name}
              className="item-icon clickable"
              onClick={() => applyModel('shoes', shoe.threeDModel, shoe.texture)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientCharacterDetails;