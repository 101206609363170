import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  ListIcon,
  Divider
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InfoOutlineIcon, CheckCircleIcon } from '@chakra-ui/icons';

export default function Payments() {
  return (

    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='lg' fontWeight='bold' color={`white`}>
            Payments
          </Text>
        </CardHeader>
        <CardBody>
          <Box p={6} maxW="800px" mx="auto" color={`white`}>
            <Heading as="h1" size="xl" mb={4} textAlign="center">
              Payments Overview
            </Heading>
            <Text fontSize="lg" mb={6}>
              This section provides an overview of the payment process, including details on the steps involved, requirements, and tips for ensuring smooth transactions.
            </Text>
            <Divider mb={6} />
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 1: Setting Up Payment Methods
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    The first step in the payment process is setting up a payment method. This includes linking a credit card, bank account, or any other supported method.
                  </Text>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Supported Payment Methods: Credit/Debit Cards, PayPal, Bank Transfer.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Ensure the payment method is verified for security purposes.
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 2: Payment Processing
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    Once a payment method is set up, payments can be processed. This step involves confirming the amount, applying any discounts, and authorizing the transaction.
                  </Text>
                  <Text>
                    <InfoOutlineIcon mr={2} />Ensure that you double-check the payment details before proceeding to avoid errors.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 3: Security & Verification
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    During payment, the system performs security checks to ensure the transaction is legitimate. This might involve two-factor authentication or other verification methods.
                  </Text>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={InfoOutlineIcon} color="blue.500" />
                      Verification ensures that only authorized payments are processed.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Keep your verification details secure to prevent unauthorized access.
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 4: Payment Confirmation & Receipt
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    After the payment is successfully processed, a confirmation message is displayed and a receipt is generated. This receipt can be downloaded or emailed to the user.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 5: Troubleshooting Payment Issues
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    If a payment fails, common issues include insufficient funds, incorrect payment details, or network errors. Users should check these factors and retry the payment.
                  </Text>
                  <Text>
                    <InfoOutlineIcon mr={2} />If issues persist, contact customer support for assistance.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </CardBody>
      </Card>
    </Flex>
  );
}
