
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { fetchClientCharacters } from 'services/clientCharacterServices';

const ClientCharacterList = () => {
  const [characters, setCharacters] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const loadCharacters = async () => {
      const data = await fetchClientCharacters();
      setCharacters(data);
    };
    loadCharacters();
  }, []);

  
  
  const handleCharacterClick = (id) => {
    history.push(`/client/character-details/${id}`);  // Correct usage of history.push
  };


  return (
    <Container fluid className='mt-5'>
      <Card  style={{ 
    background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)', 
    borderRadius: '10px', 
    boxShadow: '0 0 10px #edf2f7',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease'}}>
        <Card.Header><h1 className='mb-3 text-white'>Character Models</h1></Card.Header>
        <Card.Body>
        <Row>
        {characters.map((character) => (
          <Col md={3} key={character._id}>
            <Card onClick={() => handleCharacterClick(character._id)} style={{ cursor: 'pointer' }}>
              <Card.Img className='card-avthar' variant="top" src={character.image} />
              <Card.Body>
                <Card.Title>{character.modelName}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ClientCharacterList;
