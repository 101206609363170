import bgBody from "assets/img/background-body-admin.png";

export const bgAdmin = {
  styles: {
    global: (props) => ({
      body: {
        // bgImage: bgBody,
        bgColor: 'rgba(27,27,56,255)',
        bgSize: "cover",
        bgPosition: "center center",
      },
    }),
  },
};
