
import React, { forwardRef, useRef, useEffect, useState } from 'react';

import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MeshStandardMaterial, DoubleSide, TextureLoader, CanvasTexture } from 'three';

const Model3DActual = forwardRef(({ url, models, bodyTextureUrl, brandLogo, logoPosition, logoSize,pantsLogo, shoesLogo, pantsLogoPosition, shoesLogoPosition ,  selectedLogoPart }, ref) => {

  const modelRef = useRef();
  const [finalTorsoTexture, setFinalTorsoTexture] = useState(null);
  const [finalPantsTexture, setFinalPantsTexture] = useState(null);
  const [finalShoesTexture, setFinalShoesTexture] = useState(null);

  // Function to determine the appropriate loader based on file extension
  const getLoader = (modelUrl) => {
    const extension = modelUrl.split('.').pop().toLowerCase();
    if (extension === 'fbx') return FBXLoader;
    if (extension === 'gltf' || extension === 'glb') return GLTFLoader;
    throw new Error(`Unsupported file type: ${extension}`);
  };

  // Load the main character model
  const characterModel = useLoader(getLoader(url), url, (loader) => {
    try {
      if (loader instanceof GLTFLoader) {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/');
        loader.setDRACOLoader(dracoLoader);
      }
    } catch (error) {
      console.error("Error loading model:", error);
    }
  });

  // Load the body texture
  const bodyTexture = useLoader(TextureLoader, bodyTextureUrl);

  // Load the logo textures (if provided)
  const logoTexture = brandLogo ? useLoader(TextureLoader, brandLogo) : null;
  const pantsLogoTexture = pantsLogo ? useLoader(TextureLoader, pantsLogo) : null;
  const shoesLogoTexture = shoesLogo ? useLoader(TextureLoader, shoesLogo) : null;

  useEffect(() => {
    if (ref) {
      ref.current = modelRef.current; // Assign modelRef to the forwarded ref
    }
  }, [ref]);

  // Load the models for torso, pants, and shoes
  const torsoModel = models.torso?.modelUrl
    ? useLoader(getLoader(models.torso.modelUrl), models.torso.modelUrl)
    : null;
  const pantsModel = models.pants?.modelUrl
    ? useLoader(getLoader(models.pants.modelUrl), models.pants.modelUrl)
    : null;
  const shoesModel = models.shoes?.modelUrl
    ? useLoader(getLoader(models.shoes.modelUrl), models.shoes.modelUrl)
    : null;

  // Load the textures for torso, pants, and shoes
  const torsoTexture = models.torso?.textureUrl
    ? useLoader(TextureLoader, models.torso.textureUrl)
    : null;
  const pantsTexture = models.pants?.textureUrl
    ? useLoader(TextureLoader, models.pants.textureUrl)
    : null;
  const shoesTexture = models.shoes?.textureUrl
    ? useLoader(TextureLoader, models.shoes.textureUrl)
    : null;

  // Helper function to get the scene from the loaded model
  const getModelScene = (model) => model?.scene || model;

  // Function to dispose of mesh resources
  const disposeMesh = (mesh) => {
    if (mesh.geometry) mesh.geometry.dispose();
    if (mesh.material) {
      if (Array.isArray(mesh.material)) {
        mesh.material.forEach((mat) => mat.dispose());
      } else {
        mesh.material.dispose();
      }
    }
  };

  // Function to remove a previous part by name
  const removePreviousPart = (parent, partName) => {
    const partToRemove = parent.getObjectByName(partName);
    if (partToRemove) {
      parent.remove(partToRemove);

      partToRemove.traverse((child) => {
        if (child.isMesh) {
          disposeMesh(child);
        }
      });

      // console.log(Removed: ${partName});
    }
  };

  // Function to overlay a logo on a texture using Canvas
 // Function to overlay a logo on a texture using Canvas
const applyLogoToTexture = (baseTexture, logoTexture, position, size) => {
  // Ensure both textures are loaded and have images
  if (!baseTexture || !baseTexture.image || !logoTexture || !logoTexture.image) {
    console.error("Error: Texture or logo image is not loaded correctly.");
    return baseTexture; // Return the original texture if loading fails
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const baseImage = baseTexture.image;
  const logoImage = logoTexture.image;

  canvas.width = baseImage.width;
  canvas.height = baseImage.height;

  // Draw the base texture first
  context.drawImage(baseImage, 0, 0, baseImage.width, baseImage.height);

  // Calculate logo position and size
  const logoX = canvas.width * position.x;
  const logoY = canvas.height * position.y;
  const logoWidth = canvas.width * size.width;
  const logoHeight = canvas.height * size.height;

  // Draw the logo on the canvas
  context.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);

  return new CanvasTexture(canvas);
};

  //ddddddddddddddddddddddd

  useEffect(() => {
    if (logoTexture && torsoTexture) {
      const newTorsoTexture = applyLogoToTexture(torsoTexture, logoTexture, logoPosition, logoSize);
      setFinalTorsoTexture(newTorsoTexture);
    } else {
      setFinalTorsoTexture(torsoTexture);
    }
  }, [logoTexture, torsoTexture, logoPosition, logoSize]);
  

 // Effect to apply logos on the selected part only
 useEffect(() => {
  // Apply the logo only if the selected part matches
  if (selectedLogoPart === 'torso' && models.torso?.textureUrl && logoTexture) {
    const newTorsoTexture = applyLogoToTexture(models.torso.textureUrl, logoTexture, logoPosition, logoSize);
    setFinalTorsoTexture(newTorsoTexture);
  } else {
    setFinalTorsoTexture(models.torso?.textureUrl); // Clear logo if not selected
  }

  if (selectedLogoPart === 'pants' && models.pants?.textureUrl && logoTexture) {
    const newPantsTexture = applyLogoToTexture(models.pants.textureUrl, logoTexture, logoPosition, logoSize);
    setFinalPantsTexture(newPantsTexture);
  } else {
    setFinalPantsTexture(models.pants?.textureUrl); // Clear logo if not selected
  }

  if (selectedLogoPart === 'shoes' && models.shoes?.textureUrl && logoTexture) {
    const newShoesTexture = applyLogoToTexture(models.shoes.textureUrl, logoTexture, logoPosition, logoSize);
    setFinalShoesTexture(newShoesTexture);
  } else {
    setFinalShoesTexture(models.shoes?.textureUrl); // Clear logo if not selected
  }
}, [selectedLogoPart, logoTexture, logoPosition, logoSize, models]);


  // Effect to apply logos on torso, pants, and shoes
  useEffect(() => {
    if (torsoTexture && logoTexture) {
      const newTorsoTexture = applyLogoToTexture(torsoTexture, logoTexture, logoPosition, logoSize);
      setFinalTorsoTexture(newTorsoTexture);
    } else if (torsoTexture) {
      setFinalTorsoTexture(torsoTexture);
    }

    if (pantsTexture && pantsLogoTexture) {
      const newPantsTexture = applyLogoToTexture(pantsTexture, pantsLogoTexture, pantsLogoPosition, logoSize);
      setFinalPantsTexture(newPantsTexture);
    } else if (pantsTexture) {
      setFinalPantsTexture(pantsTexture);
    }

    if (shoesTexture && shoesLogoTexture) {
      const newShoesTexture = applyLogoToTexture(shoesTexture, shoesLogoTexture, shoesLogoPosition, logoSize);
      setFinalShoesTexture(newShoesTexture);
    } else if (shoesTexture) {
      setFinalShoesTexture(shoesTexture);
    }
  }, [torsoTexture, logoTexture, pantsTexture, pantsLogoTexture, shoesTexture, shoesLogoTexture, logoPosition, logoSize, pantsLogoPosition, shoesLogoPosition]);

  // Function to replace a part with a new model and texture
  const replacePart = (parent, partName, newModel, texture = null) => {
    removePreviousPart(parent, partName);

    if (newModel) {
      const modelScene = getModelScene(newModel).clone();

      // Assign the partName to the model's root for identification
      modelScene.name = partName;

      // Apply the texture and material settings
      modelScene.traverse((child) => {
        let finalTexture = texture;

        if (child.isMesh) {
          child.material = new MeshStandardMaterial({
            map: finalTexture || child.material.map || null,
            metalness: 0.5,
            roughness: 0.5,
            side: DoubleSide,
          });
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });

      // Add the new part to the parent model
      parent.add(modelScene);
      // console.log(Added: ${partName});
    }
  };

  // Effect to handle the initial setup and updates when models change
  useEffect(() => {
    if (modelRef.current) {
      const model = modelRef.current;
      model.position.y = -2.0;

      // Apply body texture to all relevant body parts (except for tops, bottoms, shoes)
      model.traverse((child) => {
        if (child.isMesh) {
          // Skip specific meshes for tops, bottoms, shoes
          if (
            child.name.toLowerCase().includes('top') ||
            child.name.toLowerCase().includes('bottom') ||
            child.name.toLowerCase().includes('footwear') ||
            child.name.toLowerCase().includes('shoe')
          ) {
            return; // Skip these parts
          }

          // Apply the body texture to all other body parts
          child.material = new MeshStandardMaterial({
            map: bodyTexture,
            metalness: 0.5,
            roughness: 0.5,
            side: DoubleSide,
          });

          child.castShadow = true;
          child.receiveShadow = true;
        }
      });

      // Replace torso if a new model is provided
      if (models.torso?.modelUrl && torsoModel) {
        replacePart(model, 'Wolf3D_Outfit_Top', torsoModel, finalTorsoTexture);
      } else {
        removePreviousPart(model, 'Wolf3D_Outfit_Top');
      }

      // Replace pants if a new model is provided
      if (models.pants?.modelUrl && pantsModel) {
        replacePart(model, 'Wolf3D_Outfit_Bottom', pantsModel, finalPantsTexture);
      } else {
        removePreviousPart(model, 'Wolf3D_Outfit_Bottom');
      }

      // Replace shoes if a new model is provided
      if (models.shoes?.modelUrl && shoesModel) {
        replacePart(model, 'Wolf3D_Outfit_Footwear', shoesModel, finalShoesTexture);
      } else {
        removePreviousPart(model, 'Wolf3D_Outfit_Footwear');
      }
    }
  }, [
    torsoModel,
    pantsModel,
    shoesModel,
    models,
    finalTorsoTexture,
    finalPantsTexture,
    finalShoesTexture,
    bodyTexture,
  ]);

  // Cleanup effect to remove all parts when the component unmounts
  useEffect(() => {
    const model = modelRef.current;
    return () => {
      if (model) {
        ['Wolf3D_Outfit_Top', 'Wolf3D_Outfit_Bottom', 'Wolf3D_Outfit_Footwear'].forEach(
          (partName) => removePreviousPart(model, partName)
        );
      }
    };
  }, []);

  return (
    <primitive
      ref={modelRef}
      object={getModelScene(characterModel)}
      scale={2.2}
    />
  );
  // return (
  //   <primitive ref={ref} object={characterModel.scene} scale={2.2} />
  // );
  
});

export default Model3DActual;
