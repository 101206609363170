import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  ListIcon,
  Divider
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';

export default function Help() {
  return (

    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='lg' fontWeight='bold' color={`white`}>
            Help
          </Text>
        </CardHeader>
        <CardBody>
          <Box p={6} maxW="800px" mx="auto" color={`white`}>
            <Heading as="h1" size="xl" mb={4} textAlign="center">
              Help & Workflow Overview
            </Heading>
            <Text fontSize="lg" mb={6}>
              This section provides an overview of the workflow as depicted in the diagram. Each step is explained to help you understand how the process flows and how each component interacts.
            </Text>
            <Divider mb={6} />
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 1: Initialization (API, DB, Auth)
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    The process begins with the initialization of core modules: API, Database, and Authentication. Make sure all services are running correctly before proceeding.
                  </Text>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      API: Responsible for connecting all front-end requests to back-end services.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      DB: Ensures all project-related data and assets are saved securely.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Auth: Manages user identity verification and permissions.
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 2: Assets Management
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    This step involves managing all the 3D models, textures, and other resources required for the project. The system categorizes these into different types like Top, Bottom, and Shoes.
                  </Text>
                  <Text>
                    <InfoOutlineIcon mr={2} />Make sure to upload assets in the correct format and ensure they meet the guidelines.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 3: Validation & Verification
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    After uploading assets, the validation step ensures that all files are correctly formatted and meet project standards. This includes verifying texture quality, model integrity, and more.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 4: Integration & Testing
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    During this step, assets are integrated into the environment. Testing ensures all elements function as intended, including compatibility with other components.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Step 5: Deployment & Review
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    Finally, the project is deployed for review. Ensure all details are double-checked before marking the project as complete.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </CardBody>
      </Card>
    </Flex>
  );
}
