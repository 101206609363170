

import Dashboard from "views/Client/Dashboard/Dashboard.js"



// add managemenet/ingame adds module



import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  CharactersIcon,
} from "components/Icons/Icons";

import ClientCharacterList from "views/Client/ClientCharacterList";
import ClientCharacterDetails from "views/Client/ClientCharacterDetails/ClientCharacterDetails";
import ProjectList from "views/Developer/Project/ProjectList";
import DataAnalysis from "views/Developer/DataAnalysis/DataAnalysis";
import Documentation from "views/Developer/Documentation/Documentation";
import DeveloperRegister from "views/Pages/DeveloperRegister";
import DeveloperLogin from "views/Pages/DeveloperLogin";

var developerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/developer",
  },
  {
    path: "/projects",
    name: "Projects",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: ProjectList,
    layout: "/developer",
  },

  {
    path: "/analyze",
    name: "Data Analysis",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DataAnalysis,
    layout: "/developer",
  },

  {
    path: "/documentation",
    name: "Documentation",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Documentation,
    layout: "/developer",
  },

  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: DeveloperLogin,
    layout: "/developer-auth",
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: DeveloperRegister,
    layout: "/developer-auth",
  },
 
//   {
//     path: "/character/list",
//     name: "Characters",
//     rtlName: "لوحة القيادة",
//     icon: <CharactersIcon color='inherit' />,
//     component: ClientCharacterList,
//     layout: "/client",
//   },


];
export default developerRoutes;
