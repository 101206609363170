
import React, { forwardRef, useRef, useEffect } from 'react';
import {useFrame,  useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { CanvasTexture, MeshStandardMaterial, DoubleSide } from 'three';
import { AnimationMixer } from 'three';

const ClientModelView = forwardRef(({ url, models, brandLogo, logoPosition, logoSize }, ref) => {
  const modelRef = useRef();
  const mixerRef = useRef();

  const getLoader = (modelUrl) => {
    const extension = modelUrl.split('.').pop().toLowerCase();
    if (extension === 'fbx') return FBXLoader;
    if (extension === 'gltf' || extension === 'glb') return GLTFLoader;
    throw new Error(`Unsupported file type: ${extension}`);
  };

  const characterModel = useLoader(getLoader(url), url, (loader) => {
    if (loader instanceof GLTFLoader) {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);
    }
  });

  // useEffect(() => {
  //   if (characterModel.animations && characterModel.animations.length > 0) {
  //     // Initialize the AnimationMixer with the character model
  //     mixerRef.current = new AnimationMixer(characterModel.scene);
  //     // Play the first animation clip
  //     const action = mixerRef.current.clipAction(characterModel.animations[0]);
  //     action.play();
  //   }
  // }, [characterModel]);

  // useFrame((_, delta) => {
  //   // Update the mixer for each frame
  //   mixerRef.current?.update(delta);
  // });

  const torsoModel = models.torso?.modelUrl
    ? useLoader(getLoader(models.torso.modelUrl), models.torso.modelUrl)
    : null;
  const pantsModel = models.pants?.modelUrl
    ? useLoader(getLoader(models.pants.modelUrl), models.pants.modelUrl)
    : null;
  const shoesModel = models.shoes?.modelUrl
    ? useLoader(getLoader(models.shoes.modelUrl), models.shoes.modelUrl)
    : null;

  useEffect(() => {
    if (ref) {
      ref.current = modelRef.current;
    }
  }, [ref]);

  const getModelScene = (model) => model?.scene || model;

  // Create a CanvasTexture with the logo applied at a specific position and size
  // const createLogoCanvasTexture = (baseTexture, logoUrl, position, size, partName) => {
  //   return new Promise((resolve) => {
  //     const canvas = document.createElement('canvas');
  //     const context = canvas.getContext('2d');
  //     const logoImage = new Image();
  //     logoImage.src = logoUrl;

  //     logoImage.onload = () => {
  //       canvas.width = baseTexture.image.width;
  //       canvas.height = baseTexture.image.height;

  //       // Draw the base texture on the canvas
  //       context.drawImage(baseTexture.image, 0, 0, canvas.width, canvas.height);

  //       // Calculate logo position and size on the canvas with adjustments based on partName
  //       let logoX = canvas.width * position.x;
  //       let logoY = canvas.height * position.y;
  //       let logoWidth = canvas.width * size.width;
  //       let logoHeight = canvas.height * size.height;

  //       // Adjustments based on partName
  //       if (partName === 'Wolf3D_Outfit_Top') {
  //         // Apply any specific adjustment for torso (top)
  //         logoX *= 2.1;  // Example adjustment
  //       } else if (partName === 'Wolf3D_Outfit_Bottom') {
  //         // Apply adjustments for pants
  //         logoY *= 2.05;  // Example adjustment
  //       } else if (partName === 'Wolf3D_Outfit_Footwear') {
  //         // Apply adjustments for shoes
  //         logoWidth *= 0.9;  // Example adjustment
  //         logoHeight *= 0.9;
  //       }

  //       // Draw the logo onto the base texture
  //       context.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);

  //       const logoTexture = new CanvasTexture(canvas);
  //       logoTexture.needsUpdate = true;

  //       resolve(logoTexture);
  //     };
  //   });
  // };

  const createLogoCanvasTexture = (baseTexture, logoUrl, position, size, partName) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const logoImage = new Image();
      logoImage.src = logoUrl;
  
      logoImage.onload = () => {
        canvas.width = baseTexture.image.width;
        canvas.height = baseTexture.image.height;
  
        // Draw the base texture on the canvas
        context.drawImage(baseTexture.image, 0, 0, canvas.width, canvas.height);
  
        // Calculate logo position and size on the canvas with adjustments based on partName
        let logoX = canvas.width * position.x;
        let logoY = canvas.height * position.y;
        let logoWidth = canvas.width * size.width;
        let logoHeight = canvas.height * size.height;
  
        // Adjustments based on partName
        if (partName === 'Wolf3D_Outfit_Top') {
          logoX *= 2.1;  // Example adjustment
        } else if (partName === 'Wolf3D_Outfit_Bottom') {
          logoY *= 2.05;  // Example adjustment
        } else if (partName === 'Wolf3D_Outfit_Footwear') {
          logoWidth *= 0.9;  // Example adjustment
          logoHeight *= 0.9;
        }
  
        // Draw the logo onto the base texture, flipping it vertically
        context.translate(logoX, logoY + logoHeight);
        context.scale(1, -1); // Flip the image vertically
        context.drawImage(logoImage, 0, 0, logoWidth, logoHeight);
  
        const logoTexture = new CanvasTexture(canvas);
        logoTexture.flipY = false; // Prevent Three.js from flipping the texture
        logoTexture.needsUpdate = true;
  
        resolve(logoTexture);
      };
    });
  };
  

  const applyLogoToPart = async (parentModel, partName, logoUrl, position, size) => {
    if (!parentModel || !logoUrl) return;

    parentModel.traverse(async (child) => {
      if (child.isMesh && child.name === partName && child.material && child.material.map) {
        const logoTexture = await createLogoCanvasTexture(child.material.map, logoUrl, position, size, partName);

        const material = child.material.clone();
        material.map = logoTexture;
        material.needsUpdate = true;

        child.material = material;  // Apply the new material with the logo only to this specific mesh
      } else if (child.isMesh && child.name !== partName) {
        const originalMaterial = child.material.clone();
        originalMaterial.needsUpdate = true;
        child.material = originalMaterial;
      }
    });
  };

  const replacePart = (parent, partName, newModel, logoUrl = null, position = { x: 0, y: 0 }, size = { width: 0.2, height: 0.2 }) => {
    const partToRemove = parent.getObjectByName(partName);
    if (partToRemove) parent.remove(partToRemove);

    if (newModel) {
      const modelScene = getModelScene(newModel).clone();
      modelScene.name = partName;
      parent.add(modelScene);

      if (logoUrl) applyLogoToPart(modelScene, partName, logoUrl, position, size);
    }
  };

  useEffect(() => {
    if (modelRef.current) {
      const model = modelRef.current;
      model.position.y = -2.0;

      if (models.torso?.modelUrl && torsoModel) {
        replacePart(model, 'Wolf3D_Outfit_Top', torsoModel, brandLogo, logoPosition, logoSize);
      }

      if (models.pants?.modelUrl && pantsModel) {
        replacePart(model, 'Wolf3D_Outfit_Bottom', pantsModel, brandLogo, logoPosition, logoSize);
      }

      if (models.shoes?.modelUrl && shoesModel) {
        replacePart(model, 'Wolf3D_Outfit_Footwear', shoesModel, brandLogo, logoPosition, logoSize);
      }
    }
  }, [torsoModel, pantsModel, shoesModel, models, brandLogo, logoPosition, logoSize]);

  return <primitive ref={modelRef} object={getModelScene(characterModel)} scale={2.2} />;
});


export default ClientModelView;
