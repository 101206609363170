import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import projectPdf from "assets/files/Ems.pdf"
// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Documentation() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Function called when the document is loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box p={8} maxH="100vh" align="center">
      {/* PDF Document Viewer */}
      <Document
        file={projectPdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Button
        onClick={() => setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1))}
        mr={4}
        colorScheme="blue"
      >
        Previous
      </Button>
      <Button
        onClick={() => setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages))}
        colorScheme="blue"
      >
        Next
      </Button>
    </Box>
  );
}
