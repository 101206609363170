// services/characterService.js
import axios from 'axios';
import { API_BASE_URL } from 'config';

const fetchClientCharacters = async () => {
  try {
    const clientToken = localStorage.getItem('clientToken');
    const response = await axios.get(`${API_BASE_URL}/client/characters`, {
      headers: {
        Authorization: `Bearer ${clientToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching character data:', error);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('clientToken');
      window.location.href = 'client-auth/login';
    }
    return [];
  }
};

const fetchClientCharacterDetails = async (id) => {
  try {
    const clientToken = localStorage.getItem('clientToken');
    const response = await axios.get(`${API_BASE_URL}/client/character-details/${id}`, {
      headers: {
        Authorization: `Bearer ${clientToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching character details:', error);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('clientToken');
      window.location.href = 'client-auth/login';
    }
    return null;
  }
};

export { fetchClientCharacters, fetchClientCharacterDetails };
