import React, { useState, useEffect, Suspense } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OrbitControls } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"; // Import DRACOLoader
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import Model3D from "components/Models/Model3D";

import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

// Component to render 3D model based on type
function Model({ modelUrl }) {
  const fileExtension = modelUrl.split(".").pop().toLowerCase();
  console.log(fileExtension)
  let model;
  if (fileExtension === "glb" || fileExtension === "gltf") {
    // Use DracoLoader for compressed models
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/"); // Path to Draco decoder

    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);

    model = useLoader(GLTFLoader, modelUrl, (loader) => {
      loader.setDRACOLoader(dracoLoader);
    });

    return <primitive object={model.scene} scale={[1, 1, 1]} />;
  } else if (fileExtension === "obj") {
    model = useLoader(OBJLoader, modelUrl);
    return <primitive object={model} scale={[1, 1, 1]} />;
  } else if (fileExtension === "fbx") {
    model = useLoader(FBXLoader, modelUrl);
    
    return <primitive object={model} scale={[1, 1, 1]} />;
  } else {
    console.error("Unsupported model format:", fileExtension);
    return null;
  }
}


function CharactersModule() {
  const history = useHistory();
  const [characters, setCharacters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingCharacter, setEditingCharacter] = useState(null);
  const [formData, setFormData] = useState({
    gender: "",
    modelName: "",
  });
  const [errors, setErrors] = useState({});

  // Fetch characters from the backend
  const fetchCharacters = async () => {
    try {
      const response = await fetch("https://www.portal.seiky.in/seiky-backend/admin/character/characters");

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/auth/signin");
          return;
        }
        throw new Error("Failed to fetch characters");
      }

      const data = await response.json();
      setCharacters(data || []);
    } catch (error) {
      console.error("Error fetching characters:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load characters on component mount
  useEffect(() => {
    fetchCharacters();
  }, []);

  // Handle editing a character
  const handleEdit = (character) => {
    setEditingCharacter(character);
    setFormData({ gender: character.gender || "", modelName: character.modelName || "" });
    onOpen();
  };

  // Handle adding a new character
  const handleAddCharacter = () => {
    setEditingCharacter(null);
    setFormData({ gender: "", modelName: "" });
    onOpen();
  };

  // Handle deleting a character
  const handleDelete = async (character) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://www.portal.seiky.in/seiky-backend/admin/character/characters/${character._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/auth/signin");
          return;
        }
        throw new Error("Failed to delete character");
      }
      fetchCharacters();
      alert("Character deleted successfully");
    } catch (error) {
      console.error("Error deleting character:", error);
    }
  };

  // Handle input changes in the form
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form input
  const validateForm = () => {
    const newErrors = {};
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.modelName) newErrors.modelName = "Model Name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange = (e, fieldType) => {
    const file = e.target.files[0];
  
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldType]: file,  // Save the file to the formData state (either 'image' or 'model')
      }));
    }
  };

  // Handle form submission for adding/editing a character
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const method = editingCharacter ? "PUT" : "POST";
    const url = editingCharacter
      ? `https://www.portal.seiky.in/seiky-backend/admin/character/characters/${editingCharacter._id}`
      : "https://www.portal.seiky.in/seiky-backend/admin/character/characters";
  
    const formDataToSend = new FormData();
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("modelName", formData.modelName);
  
    if (formData.image) {
      formDataToSend.append("image", formData.image);  // Append the image file
    }
  
    if (formData.threeDModel) {
      formDataToSend.append("threeDModel", formData.threeDModel);  // Append the 3D model file
    }

    if(formData.texture) {
      formDataToSend.append("texture", formData.texture);
    }
  
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method,
        body: formDataToSend,  // Send the formData containing files and other fields
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          history.push("/auth/signin");
          return;
        }
        throw new Error("Failed to save character");
      }
  
      onClose();
      fetchCharacters();
    } catch (error) {
      console.error("Error saving character:", error);
    }
  };
  

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddCharacter} alignSelf="flex-end" mb="4" colorScheme="blue">
        Add Character
      </Button>
      <Table variant="simple" color="white"> {/* Set the text color to white for the entire table */}
        <Thead>
          <Tr>
            <Th>Gender</Th>
            <Th>Model Name</Th>
            <Th>3D Model</Th>
            <Th>Image</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {characters.map((character, key) => (
            <Tr key={key}>
              <Td>{character.gender}</Td>
              <Td>{character.modelName}</Td>
              <Td>
                <div style={{ width: "150px", height: "150px" }}>
                  <Suspense fallback={<div>Loading model...</div>}>
                  <Canvas camera={{ position: [0, 0, 20] }}>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[5, 5, 5]} />
                    <Model3D modelUrl={character.threeDModel} />
                    <OrbitControls />
                  </Canvas>
                  </Suspense>
                </div>
              </Td>
              <Td>
                <Image src={character.image} width={100} height={100} alt="Character Image" />
              </Td>
              <Td>
                <Button colorScheme="blue" onClick={() => handleEdit(character)}>
                  Edit
                </Button>
                <Button colorScheme="red" ml={2} onClick={() => handleDelete(character)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for adding/editing characters */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>{editingCharacter ? "Edit Character" : "Add a New Character"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
            {/* Gender Field */}
              <FormControl isInvalid={!!errors.gender}>
                <FormLabel>Gender</FormLabel>
                <Input
                  name="gender"
                  value={formData.gender || ""}
                  onChange={handleChange}
                  placeholder="Gender"
                />
                {errors.gender && <FormErrorMessage>{errors.gender}</FormErrorMessage>}
              </FormControl>

              {/* Model Name Field */}
              <FormControl mt={4} isInvalid={!!errors.modelName}>
                <FormLabel>Model Name</FormLabel>
                <Input
                  name="modelName"
                  value={formData.modelName || ""}
                  onChange={handleChange}
                  placeholder="Model Name"
                />
                {errors.modelName && <FormErrorMessage>{errors.modelName}</FormErrorMessage>}
              </FormControl>

              {/* Image Upload Field */}
              <FormControl mt={4}>
                <FormLabel>Upload Image</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "image")} // Handle the image file change
                />
              </FormControl>

              {/* 3D Model Upload Field */}
              <FormControl mt={4}>
                <FormLabel>Upload 3D Model</FormLabel>
                <Input
                  type="file"
                  accept=".glb,.gltf,.obj"  // Accept 3D model file formats
                  onChange={(e) => handleFileChange(e, "threeDModel")} // Handle the 3D model file change
                />
                
              </FormControl>

              {/* 3D Model Upload Field */}
              <FormControl mt={4}>
                <FormLabel>Upload Texture</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "texture")} // Handle the 3D model file change
                />
                
              </FormControl>

            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingCharacter ? "Update" : "Submit"}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default CharactersModule;
